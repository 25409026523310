export const HLEnglishPapers = [
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2019",
    link: "https://drive.google.com/open?id=1Bg2ya2Mky9wuZwMhTopwS5N0H4pNo3JY",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2019",
    link: "https://drive.google.com/open?id=1DWyS4nMWO721qq8us9iyeV4m5bZAcs8V",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2018",
    link: "https://drive.google.com/open?id=1fcLMJSEbq5WiynDPhqlwcfwyYpAwWFKb",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2018",
    link: "https://drive.google.com/open?id=1HDmG49fmUKx7ummK0MQweATzVLOYfxQo",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2017",
    link: "https://drive.google.com/open?id=1U-rpGcBV--dCS4MplF5_3dwT6lrs9ccW",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2017",
    link: "https://drive.google.com/open?id=1RJhDW_gG_sSDWFMEkyFEgrYWwsrcRVMk",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2016",
    link: "https://drive.google.com/open?id=1rR7Woga_GnytwIC4jR39iIstzj_TOLFo",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2016",
    link: "https://drive.google.com/open?id=1Qpys_gOT0oHGAB1wZ4A1k5CXBwPFcetw",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2015",
    link: "https://drive.google.com/open?id=1i-WXqHMo33dmdJgypcc-DwnLc1gnC7sb",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2015",
    link: "https://drive.google.com/open?id=1r7VdlGUNzp016m4po4erIrp52_0sKfeg",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2014",
    link: "https://drive.google.com/open?id=1oYxGzjycXVxkb9pzgpHZITcuKTbSgOCn",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2014",
    link: "https://drive.google.com/open?id=1-zql2FVxxvBG3LTqK64UMjSLfmXYhzQF",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2013",
    link: "https://drive.google.com/open?id=1P2UF6lvi4WfhhXlyXelI9ZS_ipeuac04",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2013",
    link: "https://drive.google.com/open?id=1bmb-xLlWdF6sUN8Ib2YA6Gkx7QFfhgve",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2012",
    link: "https://drive.google.com/open?id=1JtznQFbIZZ_g5BGU1pHK1yX__OlVqN4J",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2012",
    link: "https://drive.google.com/open?id=14uuBBBIw4Pib-FDyPu46FjX4HKP9C_WA",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2011",
    link: "https://drive.google.com/open?id=1t_Jb412gmY-7x_OK8YD1G5WsYAjvIThp",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2011",
    link: "https://drive.google.com/open?id=1sEyzaUU_peCy8qQcWKttGovRIRO4wMhL",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2010",
    link: "https://drive.google.com/open?id=1hBYBlmFXu2IwEbrGFS9fUpKIBtpaDtO_",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2010",
    link: "https://drive.google.com/open?id=1vpW9LiwTFVw_icpkF55yP9lpXBmc5VHg",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2009",
    link: "https://drive.google.com/open?id=1_qezY6Vc1M-0hfvi4qGVxodeOqfzp5sw",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2009",
    link: "https://drive.google.com/open?id=1GBtvdGS7oFNU2FEgysaLCa4pcD1tG38a",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2008",
    link: "https://drive.google.com/open?id=1y2yh-Tp-4Wm8M-exPk37KhmydgXJ0bah",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2008",
    link: "https://drive.google.com/open?id=18U5lKJ-FgFArsbRFy4B9UvZtfuz177uD",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2007",
    link: "https://drive.google.com/open?id=1kG7FgAeRQgQuvbMcMLgo4t1HWViTHX8D",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2007",
    link: "https://drive.google.com/open?id=1U32hter8a-hTfjvMzPOFIq3bQVeAk3uJ",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2006",
    link: "https://drive.google.com/open?id=1IQ8Eea1Twb0Hns3uQgN2Z2dGfog0Z-w0",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2006",
    link: "https://drive.google.com/open?id=1bdwkn5vi7PirI8u_6eZ8j65gdTBzPUxU",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2005",
    link: "https://drive.google.com/open?id=1cg699ErsXrepKyesOP5gUrpLFO8cbUkA",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2005",
    link: "https://drive.google.com/open?id=1FL_IN17hL2iUokiJuNbt8dTsCW9QBQDx",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2004",
    link: "https://drive.google.com/open?id=1U2ZDn9RKcCkeU4fUlMPAQ9GptH738viw",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2004",
    link: "https://drive.google.com/open?id=1t7gXWVPz9frh2AjRKD--MXX9hhLrEl-j",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2003",
    link: "https://drive.google.com/open?id=1tk3ytx-q8w7iIxEv_o5pSedGkbqavUDJ",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2003",
    link: "https://drive.google.com/open?id=1Ei1cmeNYNdJbCsHlkpDEbBYMtPtRczFv",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2002",
    link: "https://drive.google.com/open?id=1hNd2q6xcUpcqXB8deeYDnx8TWy362THv",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2002",
    link: "https://drive.google.com/open?id=1Rxzt-PUeiRUxdliHYlF1eBS1WHgE97jx",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2001",
    link: "https://drive.google.com/open?id=16jlDfI2xfVGjbvV-jBsWAIOULVs5BjT3",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2001",
    link: "https://drive.google.com/open?id=1h1hlp1RjCX48ZQMyXiq671rNMFunqZVW",
  },
  {
    tipe: "MarkingScheme",
    year: "2019",
    link: "https://drive.google.com/open?id=1MEjXgKIY9ZY-QeqwGkdRPSTtqVAa3Kt8",
  },
  {
    tipe: "MarkingScheme",
    year: "2018",
    link: "https://drive.google.com/open?id=1SVySp9e4KOqZsZtsRj7L55FG4iJLkG-s",
  },
  {
    tipe: "MarkingScheme",
    year: "2017",
    link: "https://drive.google.com/open?id=1gsN6sMzFmJdE5GonqPsRhaVg3bpDWP2J",
  },
  {
    tipe: "MarkingScheme",
    year: "2016",
    link: "https://drive.google.com/open?id=1VWGe0oBY_eTCDXPrRlXiiXot6gJZnoen",
  },
  {
    tipe: "MarkingScheme",
    year: "2015",
    link: "https://drive.google.com/open?id=1yQa6EWXHgIU_7hGN5hpHfI1tCwCp8LdC",
  },
  {
    tipe: "MarkingScheme",
    year: "2014",
    link: "https://drive.google.com/open?id=1mWO6XdQKENpzLT7MLOUErvXDfNtrUouH",
  },
  {
    tipe: "MarkingScheme",
    year: "2013",
    link: "https://drive.google.com/open?id=1-OfseaMExU6JtLGaXp7AoH0VjBSDZK4_",
  },
  {
    tipe: "MarkingScheme",
    year: "2012",
    link: "https://drive.google.com/open?id=1TdwiJNt83yqwYUXYIPj5EBvJW3hIfkUK",
  },
  {
    tipe: "MarkingScheme",
    year: "2011",
    link: "https://drive.google.com/open?id=1CIUuvOWBOXsb2QmU54uLx-x5jmK5bmdM",
  },
  {
    tipe: "MarkingScheme",
    year: "2010",
    link: "https://drive.google.com/open?id=1cen5XcjcLygdtuC1B9tXVc1TrhFEBJd1",
  },
  {
    tipe: "MarkingScheme",
    year: "2009",
    link: "https://drive.google.com/open?id=1Qwu2FL_q7Sgq4rzeqEqjc7o3Hoi-4eRR",
  },
  {
    tipe: "MarkingScheme",
    year: "2008",
    link: "https://drive.google.com/open?id=1cBQqYLzxCqJBTT45g4A4nkMglw6Nv0Gh",
  },
  {
    tipe: "MarkingScheme",
    year: "2007",
    link: "https://drive.google.com/open?id=1T-8TXwGbar_aEebcU1JCsOhXRk1sC3e4",
  },
  {
    tipe: "MarkingScheme",
    year: "2006",
    link: "https://drive.google.com/open?id=17kEQzonQDpUXFs6EP6z__YAweXeDHExS",
  },
  {
    tipe: "MarkingScheme",
    year: "2005",
    link: "https://drive.google.com/open?id=1UWaoDdz2uew-z4h2_pTBQl0ByLfPoxKF",
  },
  {
    tipe: "MarkingScheme",
    year: "2004",
    link: "https://drive.google.com/open?id=1pbbqjBFbHOsBaqsc8gOl69cHlVS33iPg",
  },
  {
    tipe: "MarkingScheme",
    year: "2003",
    link: "https://drive.google.com/open?id=1tRr5f7a-9drb9rMf8RgY7TM61LBcKXjG",
  },
  {
    tipe: "MarkingScheme",
    year: "2002",
    link: "https://drive.google.com/open?id=1Rz4Ze-kbWPuwgktv3w-aICTOFsq-J0hW",
  },
  {
    tipe: "MarkingScheme",
    year: "2001",
    link: "https://drive.google.com/open?id=1Ql50bPzqwWjN1raMt-hFv6Y8kQvNsrDO",
  },
];