<div class="head">
  <ul>
    <li><a class="active" routerLink="/">Home</a></li>
    <li><a routerLink="/higher-level">Higher Level</a></li>
    <li><a routerLink="/ordinary-level">Ordinary Level</a></li>
    <li class="cp-notes-link"><a routerLink="/cp-notes">CP Notes</a></li>
  </ul>
</div>

<div class="Title">
  <h1>Welcome to Fundamental Papers</h1>
</div>

<div class="Intro">
  <ul>
    <li><a>The minimalist mobile-friendly alternative to examinations.ie</a></li>
    <li><a>I've added all HL maths, DCG, Applied Maths, Physics, English & Irish papers</a></li>
    <li><a>Ordinary papers are kinda wip, I've got english</a></li>
  </ul>
</div>