<!-- main content container -->
<div class="jumbotron">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 offset-sm-2">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<div class="footer">
  <p>~made by oisín~</p>
</div> 