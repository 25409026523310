<div class="head">
  <ul>
    <li><a routerLink="/">Home</a></li>
    <li><a routerLink="/higher-level">Higher Level</a></li>
    <li><a routerLink="/ordinary-level">Ordinary Level</a></li>
    <li class="active-cp-notes-link"><a routerLink="/cp-notes">CP Notes</a></li>
  </ul>
</div>

<div class="TopBar">
  <ul>
    <li><a routerLink="/cp-notes/graphs">Graphs</a></li>
    <li><a routerLink="/cp-notes/mathematics">Mathematics</a></li>
    <li><a routerLink="/cp-notes/binary-search">Binary Search</a></li>
    <li class="blog-link"><a routerLink="/cp-notes/blog">Blog</a></li>
  </ul> 
</div>

<div class="jumbotron">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 offset-sm-2">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>