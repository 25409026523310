<div id="ternary-search">
  <h1>Ternary Search</h1>
  <ul>
    <li>
      <h3 class = "bold">Discrete Ternary Search - </h3>
      <p>When finding local minima / maxima in a discrete range like an array, it's actually more effective and simpler to do a binary search than a ternary search</p>
      <div class = "code"><code><pre>{{discrete_ternary_search_code}}</pre></code></div>
    </li>
    <li>
      <h3 class = "bold">Golden Section Search - </h3>
      <p>When finding minima / maxima in a continuous range, one can minimise calls to f(x) by reusing one of the middle values calculated in the previous iteration, it's most optimal to do this based on little Φ</p>
      <div class = "code"><code><pre>{{golden_section_search_code}}</pre></code></div>
    </li>
  </ul>
</div>