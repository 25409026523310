<body>

  <div class="Title">
    <h1>DCG Papers</h1>
  </div>

  <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">

    <input id="year" type="text" formControlName="year" placeholder="Year">

  </form>
  <div class="Results">
    <ul>
      <li>
        <div *ngIf="hasSectionA">
          <a href = "{{ sectionALink }}" target="_blank">
            Section A
          </a>
        </div>
      </li>
      <li>
        <div *ngIf="hasSectionB">
          <a href = "{{ sectionBLink }}" target="_blank">
            Section B/C
          </a>
        </div>
      </li>
      <li>
        <div *ngIf="hasMark">
          <a href = "{{ markLink }}" target="_blank">
            Marking Scheme
          </a>
        </div>
      </li>
    </ul>
  </div>
</body>