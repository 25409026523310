<div id="game-theory">
  <h1>Game Theory</h1>
  <ul>
    <li>
      <h3 class = "bold">Sprague-Grundy Theorem - </h3>
      <p>For a nim game, if the cumulative xor of the grundy numbers of each 
game is non-zero, the first player will win, else if the second player will.
</p>
    </li>
  </ul>
</div>

<div id="number-theory">
  <h1>Number Theory</h1>
  <ul>
    <li>
      <h3 class = "bold">Euler's totient function - </h3>
      <p>φ(x) is the number of integers less than x that are coprime to x</p>
      <p>φ(x) is equal to x * product of (p-1)/(p), where p is every distinct prime factor of x</p>
      <p>ie, φ(24) = 24 * (1/2) * (2/3) = 8</p>
    </li>
    <li>
      <h3 class = "bold">Euler's theorem of modular exponents - </h3>
      <p>When calculating modular powers, a<sup>b</sup> is congruent to a<sup>φ(m) + b%φ(m)</sup>,  mod m</p>
      <p>ie, 14<sup>20</sup> % 24 = 14<sup>12</sup> % 24 = 16
    </li>
  </ul>
</div>