export const HLMathsPapers = [
  {
    tipe: "ExamPaper",
    year: "2019",
    paper: "One",
    link: "https://drive.google.com/open?id=1OKE3hwhuJCAZsHUL8M0sw00YHyM9Fz39",
  },
  {
    tipe: "ExamPaper",
    year: "2019",
    paper: "Two",
    link: "https://drive.google.com/open?id=11tPHhko7Zju4U30SQSIApgjWNn8Mix6J",
  },
  {
    tipe: "ExamPaper",
    year: "2018",
    paper: "One",
    link: "https://drive.google.com/open?id=1qJQwEBLZdhHfAR0gncERUMuxs-ZQV9F1",
  },
  {
    tipe: "ExamPaper",
    year: "2018",
    paper: "Two",
    link: "https://drive.google.com/open?id=1GXnSnpwFUSXy_BOi3xjcdVuhH6xAaHRi",
  },
  {
    tipe: "ExamPaper",
    year: "2017",
    paper: "One",
    link: "https://drive.google.com/open?id=1KcClmGcQouyvIXKyIGow12h-LOK4aZik",
  },
  {
    tipe: "ExamPaper",
    year: "2017",
    paper: "Two",
    link: "https://drive.google.com/open?id=1ilwLBgKbHUljVsajTLSVebpb0z6Qyg6z",
  },
  {
    tipe: "ExamPaper",
    year: "2016",
    paper: "One",
    link: "https://drive.google.com/open?id=1eljAD5CpIZlLTgiB9BRFc2-WbBrj_Vow",
  },
  {
    tipe: "ExamPaper",
    year: "2016",
    paper: "Two",
    link: "https://drive.google.com/open?id=1pWtUhMvKS8_rf086QzGm5ocvfjn54SLG",
  },
  {
    tipe: "ExamPaper",
    year: "2015",
    paper: "One",
    link: "https://drive.google.com/open?id=1AS8DnAszP3Kp2FI4-Rxe5f5XQHFKjFMn",
  },
  {
    tipe: "ExamPaper",
    year: "2015",
    paper: "Two",
    link: "https://drive.google.com/open?id=1WVcsRYhrn3fkUNBL8bWERgdEcVaioBHq",
  },
  {
    tipe: "ExamPaper",
    year: "2014",
    paper: "One",
    link: "https://drive.google.com/open?id=1ryiYG4Dn_Eg4xFwb9Adi8_gDXFIeccpY",
  },
  {
    tipe: "ExamPaper",
    year: "2014",
    paper: "Two",
    link: "https://drive.google.com/open?id=1R08nZ4e86h6K2AnlksYzJvCdqkvPv7o_",
  },
  {
    tipe: "ExamPaper",
    year: "2013",
    paper: "One",
    link: "https://drive.google.com/open?id=1ggdENITr5VjwtwTB1SM6Ee_V0WWXajAG",
  },
  {
    tipe: "ExamPaper",
    year: "2013",
    paper: "Two",
    link: "https://drive.google.com/open?id=1hJiCSkhOdaq9SlKovlN0lHTo_wJ7tjHT",
  },
  {
    tipe: "ExamPaper",
    year: "2012",
    paper: "One",
    link: "https://drive.google.com/open?id=1yLHZzdY2fvAmMqTgxn90QEgU559xThX-",
  },
  {
    tipe: "ExamPaper",
    year: "2012",
    paper: "Two",
    link: "https://drive.google.com/open?id=1u4vrRgO8iGuR7rTZDC-kuwAeEIYmedqB",
  },
  {
    tipe: "ExamPaper",
    year: "2011",
    paper: "One",
    link: "https://drive.google.com/open?id=1EcI3cJkjsqZXxCBDjOhoabfu_YDwv6_7",
  },
  {
    tipe: "ExamPaper",
    year: "2011",
    paper: "Two",
    link: "https://drive.google.com/open?id=1f_GnH-KrFLTmygjwecf5jYxlVzN4mViN",
  },
  {
    tipe: "ExamPaper",
    year: "2010",
    paper: "One",
    link: "https://drive.google.com/open?id=1vNvDq1kRJMFuxica59FTxlOM5vRSMtQc",
  },
  {
    tipe: "ExamPaper",
    year: "2010",
    paper: "Two",
    link: "https://drive.google.com/open?id=10lqz-Ch40B4HUcsGIy9bXjtlsy7jUg0A",
  },
  {
    tipe: "ExamPaper",
    year: "2009",
    paper: "One",
    link: "https://drive.google.com/open?id=13RwygqounJiRp9KlqYIN8hYY2b8Mmj_a",
  },
  {
    tipe: "ExamPaper",
    year: "2009",
    paper: "Two",
    link: "https://drive.google.com/open?id=17hWCR0oz1LbkxCASdOIduj29_5pxNhEY",
  },
  {
    tipe: "ExamPaper",
    year: "2008",
    paper: "One",
    link: "https://drive.google.com/open?id=1gADmICMI9voe3Yxdhix3n-X-m9Xeb0Je",
  },
  {
    tipe: "ExamPaper",
    year: "2008",
    paper: "Two",
    link: "https://drive.google.com/open?id=1i-frtVcqBB0nf176JqsL03zL0NzfrMQs",
  },
  {
    tipe: "ExamPaper",
    year: "2007",
    paper: "One",
    link: "https://drive.google.com/open?id=1EGZSM2f5YjUgvaEbnqz0zj0k_dwMqb9j",
  },
  {
    tipe: "ExamPaper",
    year: "2007",
    paper: "Two",
    link: "https://drive.google.com/open?id=1c4pIr34s89oaBVxWrcV0sHrTbLe3qMvY",
  },
  {
    tipe: "ExamPaper",
    year: "2006",
    paper: "One",
    link: "https://drive.google.com/open?id=1Vv7--ek-nYPJSPLXEVsRapwzKGGoy5-r",
  },
  {
    tipe: "ExamPaper",
    year: "2006",
    paper: "Two",
    link: "https://drive.google.com/open?id=10avQcRp3lPflYYn1TNpUfiC4IVlJGXv9",
  },
  {
    tipe: "ExamPaper",
    year: "2005",
    paper: "One",
    link: "https://drive.google.com/open?id=1ZGAjNLCd1HcgKbPfrN_jm38LVqGm45Ai",
  },
  {
    tipe: "ExamPaper",
    year: "2005",
    paper: "Two",
    link: "https://drive.google.com/open?id=17LtXXHr-1onraeUrnw_P2UdB8pgQVhi2",
  },
  {
    tipe: "ExamPaper",
    year: "2004",
    paper: "One",
    link: "https://drive.google.com/open?id=1rp-QVJmkDTqoOGtLtaXTVmK5ZSpOvz2h",
  },
  {
    tipe: "ExamPaper",
    year: "2004",
    paper: "Two",
    link: "https://drive.google.com/open?id=1P9e4Jj7K8M0DZACIEQLKDTMXjoy-q_Zl",
  },
  {
    tipe: "ExamPaper",
    year: "2003",
    paper: "One",
    link: "https://drive.google.com/open?id=1k1jDqvt2-fgpu1wEEHlQXQ6TOV50NiCn",
  },
  {
    tipe: "ExamPaper",
    year: "2003",
    paper: "Two",
    link: "https://drive.google.com/open?id=1I8lu9F16ZKwrP0u0DANcZWuQ4Byr5yUo",
  },
  {
    tipe: "ExamPaper",
    year: "2002",
    paper: "One",
    link: "https://drive.google.com/open?id=1mVSZGBpcA1GxPi2479lNz-1lRu7eltSV",
  },
  {
    tipe: "ExamPaper",
    year: "2002",
    paper: "Two",
    link: "https://drive.google.com/open?id=1uV0BqsOH6K9YsSV286eNBI902jQ0rXGD",
  },
  {
    tipe: "ExamPaper",
    year: "2001",
    paper: "One",
    link: "https://drive.google.com/open?id=1grdZNqtziqUy0rXKcweP7valH21L5S4X",
  },
  {
    tipe: "ExamPaper",
    year: "2001",
    paper: "Two",
    link: "https://drive.google.com/open?id=1lrg-pBWucReOew73QGzTpI5tiGMni-LU",
  },
  {
    tipe: "ExamPaper",
    year: "2000",
    paper: "One",
    link: "https://drive.google.com/open?id=1jssR1kXDWCgPPUyotRG73Zxoo86bBc8y",
  },
  {
    tipe: "MarkingScheme",
    year: "2019",
    paper: "",
    link: "https://drive.google.com/open?id=1p_ARszktKYOhC4Q2IaLn-q4l7GjhK94k",
  },
  {
    tipe: "MarkingScheme",
    year: "2018",
    paper: "",
    link: "https://drive.google.com/open?id=1YVaNtFcwvNzrMtJ6e8i1abcHIHr8_uP8",
  },
  {
    tipe: "MarkingScheme",
    year: "2017",
    paper: "",
    link: "https://drive.google.com/open?id=1vnzKZf89e1uIwGMVeoipQ3PRavnhylWV",
  },
  {
    tipe: "MarkingScheme",
    year: "2016",
    paper: "",
    link: "https://drive.google.com/open?id=1nqv0sv8oUDlFJ9kCv78nHNKftq3LgPC1",
  },
  {
    tipe: "MarkingScheme",
    year: "2015",
    paper: "",
    link: "https://drive.google.com/open?id=1C8w80o1gIc2c1Wa6OkErd_K6xA8J6k9e",
  },
  {
    tipe: "MarkingScheme",
    year: "2014",
    paper: "",
    link: "https://drive.google.com/open?id=1lYZjktbOPiMWtTCxMqxJVLrT6UAkIvH0",
  },
  {
    tipe: "MarkingScheme",
    year: "2013",
    paper: "",
    link: "https://drive.google.com/open?id=1PPrtQYSXDL8PXbs7cZqEPudDlwstLu7T",
  },
  {
    tipe: "MarkingScheme",
    year: "2012",
    paper: "",
    link: "https://drive.google.com/open?id=15QOr4GhZMDdNy783ZKvrZzY-Qirc678p",
  },
  {
    tipe: "MarkingScheme",
    year: "2011",
    paper: "",
    link: "https://drive.google.com/open?id=1lqkSNcgCH3LW7_OIcAX5CNLpP8VR6jop",
  },
  {
    tipe: "MarkingScheme",
    year: "2010",
    paper: "",
    link: "https://drive.google.com/open?id=1bHve1w4EtG5r3-NSqkXqHTyXTE4dmiRT",
  },
  {
    tipe: "MarkingScheme",
    year: "2009",
    paper: "",
    link: "https://drive.google.com/open?id=1LZf6vaPwA7gENC9jFmVkW0Wa2LcIFcX1",
  },
  {
    tipe: "MarkingScheme",
    year: "2008",
    paper: "",
    link: "https://drive.google.com/open?id=1AJzWDteZ_6_InIxh8OvbRPYgw478mWy0",
  },
  {
    tipe: "MarkingScheme",
    year: "2007",
    paper: "",
    link: "https://drive.google.com/open?id=1cwhO_gFzHRhD_F6FAO8dQyoRBSYi2IOu",
  },
  {
    tipe: "MarkingScheme",
    year: "2006",
    paper: "",
    link: "https://drive.google.com/open?id=1n3Vv93xtyfFbO02aLRX2gpFICgvTU_cR",
  },
  {
    tipe: "MarkingScheme",
    year: "2005",
    paper: "",
    link: "https://drive.google.com/open?id=1GOlZmIBJHhqUfSM5H47-MmRdRSyldpHe",
  },
  {
    tipe: "MarkingScheme",
    year: "2004",
    paper: "",
    link: "https://drive.google.com/open?id=1MKImss2BE3mgArW0dOoKBaSNBQswiujs",
  },
  {
    tipe: "MarkingScheme",
    year: "2003",
    paper: "",
    link: "https://drive.google.com/open?id=1x3KkT9StyamE-k4vFr2RIV37iP1mQJbp",
  },
  {
    tipe: "MarkingScheme",
    year: "2002",
    paper: "",
    link: "https://drive.google.com/open?id=1KIg4EL2iSBL6qmGfYggZUzR7Gf1SnceW",
  },
  {
    tipe: "MarkingScheme",
    year: "2001",
    paper: "",
    link: "https://drive.google.com/open?id=1UlNcocsJNVJ0Exy_5HlhcDTJXOdPARWj",
  },
];