<body>

  <div class="Title">
    <h1>Maths Papers</h1>
  </div>

  <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">

    <input id="year" type="text" formControlName="year" placeholder="Year">

  </form>
  
  <div class="Results">
    <ul>
      <li>
        <div *ngIf="hasOne">
          <a href = "{{ oneLink }}" target="_blank">
            Paper One
          </a>
        </div>
      </li>
      <li>
        <div *ngIf="hasTwo">
          <a href = "{{ twoLink }}" target="_blank">
            Paper Two
          </a>
        </div>
      </li>
      <li>
        <div *ngIf="hasMark">
          <a href = "{{ markLink }}" target="_blank">
            Marking Scheme
          </a>
        </div>
      </li>
    </ul>
  </div>
</body>