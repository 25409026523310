export const OLEnglishPapers = [
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2019",
    link: "https://drive.google.com/open?id=1kPRhOhqOA50UhnBCypqFMvy0Fd2tXn14",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2019",
    link: "https://drive.google.com/open?id=1L1ZPb_2FwunT6o4aLE4exzA6wp2V3KeC",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2018",
    link: "https://drive.google.com/open?id=1dX_nHDVcbepolXOR3C9TxYMjGy-TD79N",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2018",
    link: "https://drive.google.com/open?id=1MLiuZIfOSM_8guAEbbIZfIY9597YhqjF",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2017",
    link: "https://drive.google.com/open?id=1cr4n1PnyS0GUrxNV_gnvjR3Nomd9_-zR",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2017",
    link: "https://drive.google.com/open?id=1_6R1aQZRuMcusrpdSy_9ADQ1NefK1o07",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2016",
    link: "https://drive.google.com/open?id=1YI7EVWpKN7lOBBQz9qnuGXT-xIJA1bAd",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2016",
    link: "https://drive.google.com/open?id=13g58j-7Ku3Tuu05rZkYOrEr6Iiub3so9",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2015",
    link: "https://drive.google.com/open?id=1vNHVlVQ7PtYgu2oNQPSYi706-nSU9v-a",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2015",
    link: "https://drive.google.com/open?id=1bzkKbE7h78qxWLnVK0kCvR0xW78_spHv",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2014",
    link: "https://drive.google.com/open?id=1mQCw-UQ-1eaNDf6RGtWPzPi-J_DwOofl",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2014",
    link: "https://drive.google.com/open?id=16GyYT07eSDA8tgfZDn0iwDDDzcwjXIV3",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2013",
    link: "https://drive.google.com/open?id=1VJqnoMUPA626FYuefnmzgf23F4EuMEqq",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2013",
    link: "https://drive.google.com/open?id=1S9naQW1eE0_YG9VrF-MxGmYzKir8mTyT",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2012",
    link: "https://drive.google.com/open?id=1NfIB2csT84hVSJPJFPOTY1mA6-GBeMlW",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2012",
    link: "https://drive.google.com/open?id=1drcoT8-87oOD_MkkF7syVZJbNfZgSLuQ",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2011",
    link: "https://drive.google.com/open?id=1eMli3ewg_YOsQ4N6tHvx_w6c9n9J1CxE",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2011",
    link: "https://drive.google.com/open?id=1YEku-g3oxzOrIqRnID0hdU0L-QOxxj5g",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2010",
    link: "https://drive.google.com/open?id=1DJJDISGu9OPLYei1EPaYw8juStnh8sVX",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2010",
    link: "https://drive.google.com/open?id=1Ap0tJmT20353Cfy6Ae9tIX-9t7FRpvei",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2009",
    link: "https://drive.google.com/open?id=1f0M4iVVDwcq-6jEAOvk94FQcIvum1j18",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2009",
    link: "https://drive.google.com/open?id=1hhmBPcuUuWY73q31pav4jYKm2D6snkRL",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2008",
    link: "https://drive.google.com/open?id=11fMUnhX8ubuTN8BhUoKkkqSFhIFOpJ5v",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2008",
    link: "https://drive.google.com/open?id=1BmcYMqkQYsP7e6khPmCweVwVyRyVfIuP",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2007",
    link: "https://drive.google.com/open?id=1WsGnSpd6SpMoP8mrnfEVhuLx8Ajq326K",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2007",
    link: "https://drive.google.com/open?id=1BI_ZPXx5BI7Q1vJTRkyH_G6Z71vgp5ec",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2006",
    link: "https://drive.google.com/open?id=1Jlixc4_xOyo-I63R4_USS9XUDrtAn9UD",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2006",
    link: "https://drive.google.com/open?id=1f2KXH4O30Nx7MT8TyElX19p8TfgoRNx3",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2005",
    link: "https://drive.google.com/open?id=1iyiuw-kCb4cvh-DZPOhMF87VYH8X5vqr",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2005",
    link: "https://drive.google.com/open?id=1CQvx9IP8NEDpknaRHNSxcjNPw4mjWWiJ",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2004",
    link: "https://drive.google.com/open?id=1tQe4Lue8XjvWpW2nw-GJMFH1uqDkXyua",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2004",
    link: "https://drive.google.com/open?id=1BqaVoWeLxJZ68HVXiMiXzfcV2ee8eZBC",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2003",
    link: "https://drive.google.com/open?id=1ZofmYwYPCfHWgAn7wyyEkcRjAfc0BZnO",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2003",
    link: "https://drive.google.com/open?id=1P8Mt27irZUw3bbDzc-L5TOBbyxM8zGNm",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2002",
    link: "https://drive.google.com/open?id=1YgwHyRE0UbFYc_9MU7L3HEJFlDN9Kn6b",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2002",
    link: "https://drive.google.com/open?id=1BdQfgketCMEHA6Cz7lnmtqEH9TXqTmrK",
  },
  {
    tipe: "ExamPaper",
    paper: "One",
    year: "2001",
    link: "https://drive.google.com/open?id=1a5X9drea2KA4e34-u4SAXjPBvmeBDX9x",
  },
  {
    tipe: "ExamPaper",
    paper: "Two",
    year: "2001",
    link: "https://drive.google.com/open?id=13A9d6af-v5bnPHewbzIJi_MFLWLkN5pK",
  },
  {
    tipe: "MarkingScheme",
    year: "2019",
    link: "https://drive.google.com/open?id=1Dmgrn1e2stpM5K9-tUFCTG6OJy91kqfz",
  },
  {
    tipe: "MarkingScheme",
    year: "2018",
    link: "https://drive.google.com/open?id=14nx4U8kYT7e3V68B2hoqBdLDYZz6i_tc",
  },
  {
    tipe: "MarkingScheme",
    year: "2017",
    link: "https://drive.google.com/open?id=1d7I-LLu4PuZl8oZFKA53VrGID7YjgAW9",
  },
  {
    tipe: "MarkingScheme",
    year: "2016",
    link: "https://drive.google.com/open?id=1Da1_VTwcikxRrHR7QqEtvr-smgnDC17u",
  },
  {
    tipe: "MarkingScheme",
    year: "2015",
    link: "https://drive.google.com/open?id=1V_ZZz4sKFgEcsvZfl8yw1896K2MJNkfv",
  },
  {
    tipe: "MarkingScheme",
    year: "2014",
    link: "https://drive.google.com/open?id=1GGfTcaQHRXLw63LBFMR0saIFrrXJO9lx",
  },
  {
    tipe: "MarkingScheme",
    year: "2013",
    link: "https://drive.google.com/open?id=10ydIv_auBUfKeeFG27CjNbiDoUzJLyCr",
  },
  {
    tipe: "MarkingScheme",
    year: "2012",
    link: "https://drive.google.com/open?id=1Kr_x9PXeqCj70_G7pDqFKHNp7GXORLi4",
  },
  {
    tipe: "MarkingScheme",
    year: "2011",
    link: "https://drive.google.com/open?id=1vFvvPxdAN5RakWlc7lO79lrgAYnuXxdp",
  },
  {
    tipe: "MarkingScheme",
    year: "2010",
    link: "https://drive.google.com/open?id=10Y7pyIHaN-tOudP1fgIp8xohL4_ToIZC",
  },
  {
    tipe: "MarkingScheme",
    year: "2009",
    link: "https://drive.google.com/open?id=1ugQA4Wni2IvBKrP176BOyqaryHxzzZaY",
  },
  {
    tipe: "MarkingScheme",
    year: "2008",
    link: "https://drive.google.com/open?id=1U28vh0ZGtNw180-bSt-i6yg8UGVk07Lz",
  },
  {
    tipe: "MarkingScheme",
    year: "2007",
    link: "https://drive.google.com/open?id=1VwTQrMR3GiRWTGEG3GKrdWZxG_QxZqyM",
  },
  {
    tipe: "MarkingScheme",
    year: "2006",
    link: "https://drive.google.com/open?id=1u4O3bSM1g3FCuR21JtZcCUf6F4Kj4A4e",
  },
  {
    tipe: "MarkingScheme",
    year: "2005",
    link: "https://drive.google.com/open?id=1vx5JP5tgZ011cTVsobCnDerzUVGTRQS9",
  },
  {
    tipe: "MarkingScheme",
    year: "2004",
    link: "https://drive.google.com/open?id=19hfBmLhgQFEQRUGhyhqG4LOoLdankAnN",
  },
  {
    tipe: "MarkingScheme",
    year: "2003",
    link: "https://drive.google.com/open?id=19nse7LG1biOzflo83iHLlX35hhL_znha",
  },
  {
    tipe: "MarkingScheme",
    year: "2002",
    link: "https://drive.google.com/open?id=1lciKtMFG5knNFkatGwjjsq8kvA6G3mjn",
  },
  {
    tipe: "MarkingScheme",
    year: "2001",
    link: "https://drive.google.com/open?id=1fgFxB9ozI4jsnZckfUou5ByONHORQm08",
  },
];